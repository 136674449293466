<template>
  <template v-if="!loading">
    <Toast />
    <div class="col-12 mb-3" v-for="spectacle in spectacles" :key="spectacle">
      <div class="spectacle-list-v2 grid">
        <div class="image-container col-md-6 col-sm-12">
          <img
            class="image-content"
            :src="'data:image/jpeg;base64,' + spectacle.picture.content"
          />
        </div>
        <div
          class="col-md-6 spectacle-details d-flex flex-column justify-content-between"
        >
          <div class="grid">
            <div class="col-12 name">
              <b>{{ spectacle.name }}</b>
            </div>
            <div class="col-12 location">
              <span class="city">{{ spectacle.salle.city }}</span> -
              <span class="name">{{ spectacle.salle.name }}</span>
            </div>
          </div>
          <div class="row mt-auto">
            <div class="col-6">
              <Button
                label="Voir les photos"
                v-if="getModuleAccess('photos')"
                class="jm-red"
                style="width: 100%"
                :href="spectacle.webLink"
                @click="openSpectacleDialog(spectacle)"
              />
            </div>
            <div class="col-6">
              <Button
                v-if="getModuleAccess('commentaries')"
                label="Écrire un commentaire"
                class="jm-white"
                @click="openCommentaryDialog(spectacle)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="showCommentaryDialog"
      :style="{ width: '500px' }"
      header="Écrire un commentaire"
      :modal="true"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-3" style="padding-top: 9px">
            <p>Votre note :</p>
          </div>
          <div class="col-9">
            <Rating
              v-model="commentary.note"
              :cancel="false"
              :stars="5"
            ></Rating>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ck-editor
              label="Commentaire"
              v-model="commentary.content"
            ></ck-editor>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-6">
            <ak-input-text
              label="Votre nom (facultatif)"
              v-model="commentary.author"
            ></ak-input-text>
          </div>
          <div class="col-6">
            <ak-dropdown
              label="Date du spectacle"
              v-model="commentary.spectacleDateId"
              :options="spectacle.spectacleDates"
              optionLabel="dateFormatted"
              optionValue="id"
            ></ak-dropdown>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!-- dropdown of spectacle dates stocked in spectacle.spectacleDates-->
          </div>
        </div>
      </div>
      <div class="p-dialog-footer">
        <Button
          label="Envoyer"
          class="jm-red"
          icon="pi pi-check"
          @click="sendCommentary()"
        ></Button>
        <Button
          label="Fermer"
          icon="pi pi-times"
          class="p-button-text jm-red-color"
          @click="showCommentaryDialog = false"
        />
      </div>
    </Dialog>
  </template>
  <template v-else>
    <div
      class="loader-container d-flex justify-content-center align-items-center"
    >
      <div class="loader"></div>
    </div>
  </template>
</template>
<script>
import SpectacleService from "@/service/SpectacleService";
import CommentaryService from "@/service/CommentaryService";
import randomRef from "@mixins/randomRef";
import "mdb-vue-ui-kit/css/mdb.min.css";
import { formatDateTime } from "/src/utils/utils.js";
import CkEditor from "@components/CkEditor.vue";
import AkInputText from "@/components/input/AkInputText";
import AkDropdown from "@/components/input/AkDropdown";
import { getModuleAccess } from "../../utils/utils";

export default {
  name: "SpectacleList",

  components: { CkEditor, AkInputText, AkDropdown },

  mixins: [randomRef],

  data() {
    return {
      loading: false,
      spectacles: [],
      showCommentaryDialog: false,
      spectacle: {},
      commentary: {
        note: 5,
      },
    };
  },

  spectacleService: null,
  commentaryService: null,

  created() {
    this.spectacleService = new SpectacleService();
    this.commentaryService = new CommentaryService();
  },

  mounted() {
    this.loading = true;
    this.spectacleService.passedSpectacles().then((data) => {
      this.spectacles = data;
      this.loading = false;
    });
  },

  methods: {
    openCommentaryDialog(spectacle) {
      this.spectacle = { ...spectacle };
      this.commentary = {
        spectacleId: spectacle.id,
        note: 5,
      };
      this.showCommentaryDialog = true;
    },

    sendCommentary() {
      //catch error
      this.commentaryService
        .create(this.commentary)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: "Commentaire envoyé",
            detail: "Merci pour votre commentaire",
            life: 3000,
          });
          this.showCommentaryDialog = false;
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erreur",
            detail: "Une erreur est survenue lors de l'envoi du commentaire",
            life: 3000,
          });
        });
    },

    isDateAfterToday(date) {
      const today = new Date();
      const spectacleDate = new Date(date);

      return spectacleDate > today;
    },
    formatDateTime,
    getModuleAccess,
    goToRegisterPage(specId, dateId) {
      return "/public/spectacle/" + specId + "/register/" + dateId;
      //route.push({name: "spectacleRegister", params: { spectacleId: specId }});
    },
  },
};
</script>

<style lang="scss">
.header-spectacles-list {
  padding-left: 40px;
}

.content {
  padding: 40px 15% 40px 15%;
}

//content but with less padding on mobile
@media (max-width: 768px) {
  .content {
    padding: 40px 5% 40px 5%;
  }
  .name {
    margin-bottom: 0px !important; /* Réduction de la marge pour le nom */
  }

  .city {
    margin-bottom: 0p !important; /* Réduction de la marge pour la ville */
  }
  .location {
    margin-bottom: 0px !important; /* Réduction de la marge pour le lieu */
  }
}

.image-content {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 240px;
  border-radius: 10px;
}

.image-container {
  padding: 0;
}

.grid {
  margin: 0;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}

.loader::before {
  border-color: #f03355 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.loader::after {
  margin: 8px;
}

@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}

.fade-in {
  animation: fadeInAnimation 0.3s ease-in;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.loader-container {
  height: 50vh;
  /* Si vous voulez centrer verticalement */
}
.description-wrapper {
  position: relative;
  overflow: hidden;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Vous pouvez ajuster la taille de la police, la hauteur de ligne, etc. selon vos besoins */
}

.spectacle-details {
  font-family: Arial, sans-serif;
  color: #333;
}

.name {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.location {
  font-size: 1em;
  margin-bottom: 5px;
}

.city {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.date {
  font-size: 1em;
  margin-bottom: 5px;
}

.description-wrapper {
  margin-top: 10px;
}

.description {
  font-size: 0.9em;
  line-height: 1.4;
}
</style>
