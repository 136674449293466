<template>
  <ck-editor
    v-if="!loading"
    v-model="partners.valueString"
    className="ck-readonly"
    disabled
  />
</template>
<script>
import randomRef from "@mixins/randomRef";
import "mdb-vue-ui-kit/css/mdb.min.css";
import VariableService from "@services/VariableService";
import CkEditor from "@components/CkEditor.vue";

export default {
  name: "PartnersPublicPage",

  components: { CkEditor },

  mixins: [randomRef],

  data() {
    return {
      loading: true,
      partners: null,
    };
  },

  variableService: null,

  created() {
    this.variableService = new VariableService();
  },

  mounted() {
    this.variableService.variable("partners").then((data) => {
      this.partners = data;
      this.loading = false;
    });
  },

  methods: {},
};
</script>

<style lang="scss"></style>
