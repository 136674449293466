<template>
  <template v-if="!emailSended">
    <p>
      Si vous souhaitez nous contacter, remplissez le formulaire ci-dessous.
      Nous vous recontacterons dans les plus brefs délais.
    </p>
    <ak-input-text
      required="true"
      v-model="this.message.emailFrom"
      label="Votre email *"
      className="mt-5"
    ></ak-input-text>

    <ck-editor
      v-model="this.message.content"
      className="small-ckeditor mt-3"
      label="Contenu du message"
    />

    <div class="d-flex justify-content-end mt-3">
      <Button
        label="Envoyer"
        class="jm-red"
        @click="createContactMessage(message)"
      />
    </div>
  </template>

  <template v-else>
    <!-- please copilot, write me A text to thank user for sending a message (centered) + in another row a button (centered) to back (emailSended = false)-->
    <div class="text-center">
      <h2>Merci !</h2>
      <p>
        Nous avons bien reçu votre message, nous allons vous répondre dans les
        plus brefs délais.
      </p>
      <Button label="Retour" class="jm-red" @click="emailSended = false" />
    </div>
  </template>
</template>
<script>
import AkInputText from "@/components/input/AkInputText";
import CkEditor from "@components/CkEditor.vue";
import ContactService from "@/service/ContactService";

export default {
  name: "ContactPublicPage",

  components: { AkInputText, CkEditor },

  mixins: [],

  data() {
    return {
      loading: true,
      message: {},
      emailSended: false,
    };
  },

  contactService: null,

  created() {
    this.contactService = new ContactService();
  },

  mounted() {},

  methods: {
    createContactMessage(message) {
      if (!message.emailFrom || !message.content) {
        this.$toast.add({
          severity: "error",
          summary: "Erreur",
          detail: "Veuillez remplir tous les champs obligatoires",
          life: 3000,
        });
        return;
      }
      this.contactService
        .createContactMessage(message)
        .then(() => {
          this.emailSended = true;
          this.$toast.add({
            severity: "success",
            summary: "Message envoyé",
            detail: "Votre message a bien été envoyé",
            life: 3000,
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: "Erreur",
            detail: "Une erreur est survenue lors de l'envoi du message",
            life: 3000,
          });
        });
    },
  },
};
</script>

<style lang="scss"></style>
