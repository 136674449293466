<template>
  <div class="wrapper">
    <!-- A header with a logo, the name of the project, a search field -->
    <div class="header-spectacles-list">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <a href="/dashboard"
              ><img
                :src="'../../images/jmf_logo_round.png'"
                style="height: 40px"
            /></a>
            <h2 class="project-name ml-2 mb-0">JMFrance La Roche-sur-Yon</h2>

            <!--<ak-input-text
              class="ml-4 mb-0 col-md-8"
              placeholder="Rechercher"
            /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="fade-in">
        <TabView class="tabview-custom">
          <TabPanel>
            <template #header>
              <div class="flex align-items-center gap-2">
                <Avatar image="../../images/coming-icon.png" shape="circle" />
                <span class="font-bold white-space-nowrap"
                  >Spectacles à venir</span
                >
              </div>
            </template>
            <p class="m-0 fade-in">
              <spectacle-list-coming />
            </p>
          </TabPanel>
          <TabPanel v-if="getModuleAccess('passed_spectacles')">
            <template #header>
              <div class="flex align-items-center gap-2">
                <Avatar image="../../images/past-icon.png" shape="circle" />
                <span class="font-bold white-space-nowrap"
                  >Spectacles passés</span
                >
              </div>
            </template>
            <p class="m-0 fade-in">
              <spectacle-list-passed />
            </p>
          </TabPanel>
          <TabPanel v-if="getModuleAccess('partners')">
            <template #header>
              <div class="flex align-items-center gap-2">
                <Avatar image="../../images/partners.png" shape="circle" />
                <span class="font-bold white-space-nowrap">Partenaires</span>
              </div>
            </template>
            <p class="m-0 fade-in">
              <partners-public-page />
            </p>
          </TabPanel>
          <TabPanel v-if="getModuleAccess('contact')">
            <template #header>
              <div class="flex align-items-center gap-2">
                <Avatar image="../../images/contact.png" shape="circle" />
                <span class="font-bold white-space-nowrap">Contact</span>
              </div>
            </template>
            <p class="m-0 fade-in">
              <contact-public-page />
            </p>
          </TabPanel>
        </TabView>
      </div>
    </div>

    <footer class="footer">
      <div class="display: flex; flex-direction: column;">
        <p style="margin-top: 7px">Retrouvez nous sur nos réseaux sociaux :</p>

        <section class="mb-2">
          <a
            class="btn btn-outline-light btn-floating m-1 facebook-button"
            
            role="button"
          >
            <i class="fab fa-facebook-f"></i>
          </a>

          <a
            class="btn btn-outline-light btn-floating m-1 instagram-button"
            
            role="button"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </section>
 
<!--        <a href="mailto:jmfrancereseaux@gmail.com" style="color: #ea575c"
          >Contactez-nous en cas de problème à l'adresse :
          jmfrancereseaux@gmail.com</a
        >-->
      </div>
    </footer>
  </div>
</template>

<script>
import randomRef from "@mixins/randomRef";
import "mdb-vue-ui-kit/css/mdb.min.css";
import SpectacleListComing from "./SpectacleListComing.vue";
import SpectacleListPassed from "./SpectacleListPassed.vue";
import PartnersPublicPage from "../Partners/PartnersPublicPage.vue";
import ContactPublicPage from "../Contact/ContactPublicPage.vue";
import ModuleAccessService from "@services/ModuleAccessService";
import store from "@store";
import { getModuleAccess } from "../../utils/utils";

export default {
  name: "SpectacleList",

  components: {
    SpectacleListComing,
    SpectacleListPassed,
    PartnersPublicPage,
    ContactPublicPage,
  },

  mixins: [randomRef],

  data() {
    return {};
  },

  spectacleService: null,

  moduleAccessService: null,
  created() {
    this.moduleAccessService = new ModuleAccessService();
  },

  mounted() {
    this.moduleAccessService.getAllModuleAccesses().then((data) => {
      this.moduleAccesses = data;
      store.dispatch("saveModuleAccesses", this.moduleAccesses);
    });
  },

  methods: {
    getModuleAccess,
  },
};
</script>

<style lang="scss">
.header-spectacles-list {
  padding-left: 40px;
}

.content {
  padding: 40px 15% 100px 15%;
}

@media (max-width: 768px) {
  .content {
    padding: 40px 5% 100px 5%;
  }
}

.fade-in {
  animation: fadeInAnimation 0.3s ease-in;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-color: #ccc #0000;
  animation: l16 1s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
}

.loader::before {
  border-color: #f03355 #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}

.loader::after {
  margin: 8px;
}

@keyframes l16 {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Assure que le wrapper occupe toujours toute la hauteur de la fenêtre */
  position: relative; /* Positionnement relatif pour que le footer soit positionné correctement */
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(38, 38, 38, var(--mdb-bg-opacity)) !important;
  color: white;
  text-align: center;
  margin-top: 20px;
}
</style>
./SpectacleListComingSpectacles.vue
