import Api from "@/service/api";

export default class CommentaryService {
  getCommentary(commentaryId) {
    return Api()
      .get("/commentaries/" + commentaryId)
      .then((res) => res.data);
  }

  create(commentary) {
    return Api(false)
      .post("/commentaries", commentary)
      .then((res) => res.data);
  }

  update(commentary) {
    return Api()
      .put("/commentaries/" + commentary.commentaryId, commentary)
      .then((res) => res.data);
  }

  delete(commentary) {
    return Api()
      .delete("/commentaries/" + commentary.commentaryId)
      .then(() => commentary);
  }

  getAllcommentaries() {
    return Api()
      .get("/commentaries")
      .then((res) => res.data);
  }
}
